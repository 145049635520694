<template>
    <Header :isShow="isShow" />
    <div class="wraper">
        <!-- 简介 -->
        <div class="introduction_box" ref="header">
            <div class="Introduction max_small_box">
                <img src="@/assets/images/news.png" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import AboutTop from '../../components/about/AboutTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

export default {
    name: 'nationalInspectionDetailPdf',
    components: { Header, Footer, FloatingFrame, AboutTop },
    setup() {
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                handleScroll(header.value.offsetTop);
            })
        });

        return { isShow, header }
    }
}
</script>

<style lang="scss" scoped>
/* 简介 */
.introduction_box{
    margin-top: 60px;
}
.Introduction{
    padding: 0 30px;
    img{
        width: 100%;
    }
}
</style>